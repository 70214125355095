@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@layer base {
  /** Public Sans */
  @font-face {
    font-family: "Public Sans";
    src: url("./assets/fonts/public_sans/PublicSans-ExtraBold.ttf")
      format("truetype");
    font-weight: 800;
    font-display: swap;
    font-style: normal;
  }

  @font-face {
    font-family: "Public Sans";
    src: url("./assets/fonts/public_sans/PublicSans-Bold.ttf")
      format("truetype");
    font-weight: 700;
    font-display: swap;
    font-style: normal;
  }
  @font-face {
    font-family: "Public Sans";
    src: url("./assets/fonts/public_sans/PublicSans-SemiBold.ttf")
      format("truetype");
    font-weight: 600;
    font-display: swap;
    font-style: normal;
  }
  @font-face {
    font-family: "Public Sans";
    src: url("./assets/fonts/public_sans/PublicSans-Regular.ttf")
      format("truetype");
    font-weight: 400;
    font-display: swap;
    font-style: normal;
  }
  @font-face {
    font-family: "Public Sans";
    src: url("./assets/fonts/public_sans/PublicSans-Light.ttf")
      format("truetype");
    font-weight: 300;
    font-display: swap;
    font-style: normal;
  }
}

.portfolio-value {
  transition: all 0.3s ease-in-out;
}
